export default {
  // you can also specify a specific iso timestamp
  // startsAt: new Date().toISOString(),
  startsAt: new Date("October 9, 2020 22:30:00").toISOString(), // UTC+3
  streams: [
    {
      color: "#FF9600",
      title: "Coworking Bansko",
      subtitle: "Silent Disco Stream",
      url:
        // "https://soundcloud.com/littkemusic/funk-of-the-future-high-energy-future-funk-mix",
        "https://music.cbass.dev/mp3/psychedelic-progressive-goa-trance.mp3",
    },
  ],
};